export class UserPathConstants {
  public static readonly USERS_OVERVIEW = '/admin/manage-users';
  public static readonly ADD_USER = '/admin/manage-users/add';
  public static readonly EDIT_USER = '/admin/manage-users/edit';
}

export class DashboardPathConstants {
  public static readonly DASHBOARD_OVERVIEW = '/dashboard';
}

export class LoginPathConstants {
  public static readonly LOGIN = '/login';
  public static readonly FORGOT_PASSWORD = '/forgot-password';
}

export class DummyPathConstants {
  public static readonly MISSIONS_PAGE = '/missions';
  public static readonly FLEET_PAGE = '/fleet';
  public static readonly PILOTS_CREW_PAGE = '/pilot-crew';
  public static readonly TASKS_PAGE = '/tasks';
  public static readonly CLIENTS_PAGE = '/clients';
  public static readonly TRAINING_PAGE = '/training';
  public static readonly SETTINGS_PAGE = '/settings';
}

export class ContactPathConstants {
  public static readonly CONTACT = '/contact';
  public static readonly PERSONS_OVERVIEW = '/contact/manage-persons';
}

export class PilotCrewPathConstants {
  public static readonly CERTIFICATIONS = 'certifications';
  public static readonly PILOTS = 'pilots';
  public static readonly CREW = 'crew';
}

export class CertificationPathConstants {
  public static readonly EDIT = 'edit';
  public static readonly RENEW = 'renew';
}

export class RolePathConstants {
  public static readonly ROLES_OVERVIEW = '/admin/manage-roles';
  public static readonly EDIT_ROLE = '/admin/manage-roles/edit';
}

export class GenericPathConstants {
  public static readonly ADD = 'add';
  public static readonly EDIT = 'edit';
  public static readonly DELETE = 'delete';
}
